import React from 'react'
import BJELogo from '../assets/img/Logo.svg'

export default function NavBar() {
    return (
        <header id="page-nav">
            <nav className="header-content navbar navbar-expand-lg navbar-light pb-0 pt-0 pr-0">
                <div className="collapse navbar-collapse header-navigation" id="navbarNav">
                    <ul className="navigation-main-list navbar-nav">
                        <a className="logo navbar-brand d-none d-lg-inline-block" href="/">
                            <img src={BJELogo} alt="logo"/>
                        </a>
                    </ul>
                </div>
            </nav>
        </header>
    )
}
