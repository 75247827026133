import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

const StyledRating = withStyles({
    iconFilled: {
        color: '#009fe3',
    },
    iconHover: {
        color: '#009fe3',
    },
})(Rating);

const sendRating = (data) => {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", process.env.REACT_APP_API_URL + "setrating");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(data));
}

export default function StarRating({ id, rating }) {
    const [value, setValue] = React.useState(rating);
    const setRating = (value) => {
        setValue(value)
        sendRating({
            "id": id,
            "stars": value,
        })
    }
    return (
        <div>
            <Box component="fieldset" mb={3} borderColor="transparent">
                <StyledRating
                    name="simple-controlled"
                    value={value}
                    onChange={(event, newValue) => {
                        setRating(newValue);
                    }}
                />
            </Box>
        </div>
    );
}
