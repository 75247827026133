import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import DashboardComponent from "./Components/Dashboard";

export default function Playbook() {
  return (
    <Router>
      <Switch>
        <Route exact path="/:id?">
          <Dashboard />
        </Route>
      </Switch>
    </Router>
  );
}

function Dashboard() {
  let { id } = useParams();
  
  return (
    <DashboardComponent id={id} />
  )
}
