import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

const StyledRating = withStyles({
    iconFilled: {
        color: '#009fe3',
    },
    iconHover: {
        color: '#009fe3',
    },
})(Rating);

const sendRating = (data, products) => {
    let newProd = products
    newProd[data.prodId] = data.stars
    let postData = {
        "id": data.id,
        "products": products,
    }
    let xhr = new XMLHttpRequest();

    xhr.open("POST", process.env.REACT_APP_API_URL + "setproductrating");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(postData));
}

export default function ProductRating({ id, productRating, prodId }) {
    const [value, setValue] = React.useState(productRating[prodId]);
    const setRating = (value) => {
        setValue(value)
        sendRating({
            "id": id,
            "stars": value,
            "prodId": prodId,
        }, productRating)
    }
    return (
        <div>
            <Box component="fieldset" mb={3} borderColor="transparent">
                <StyledRating
                    name={`${prodId}-rating`}
                    value={value}
                    onChange={(event, newValue) => {
                        setRating(newValue);
                    }}
                />
            </Box>
        </div>
    );
}
