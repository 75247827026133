import React from 'react'

export default function Footer() {
    return (
        <div id="footer">
            <footer className="page-footer pt-5">
                <div className="footer-meta pt-4 pb-4 mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-9 d-inline-block links">
                                <span>© Busch-Jaeger 2024</span>
                                <a href="https://www.busch-jaeger.de/datenschutzerklaerung">Datenschutz</a>
                                <a href="https://www.busch-jaeger.de/impressum">Impressum</a>
                                <a href="https://www.busch-jaeger.de/">AGB</a>
                                <a href="https://www.busch-jaeger.de/kontakt">Kontakt</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
