import React from 'react'
import {
  PDFDownloadLink,
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer'
import Averta from '../../assets/fonts/Averta-Regular.ttf'
import AvertaLight from '../../assets/fonts/Averta-Light.ttf'
import BJELogo from '../../assets/img/Logo.png'
import CoverBackground from '../../assets/img/0293_10_Jalousiesteuerung_170608_extra_teppich.jpg'

const options = {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
  year: 'numeric',
}
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'AvertaLight',
    paddingTop: 20,
  },
  section: {
    margin: 0,
    padding: 40,
    fontFamily: 'AvertaLight',
  },
  image: {
    width: '30%',
    padding: 10,
  },
  bjeLogoTopRight: {
    width: '20%',
    top: 20,
    right: 15,
    position: 'absolute',
  },
  coverImage: {
    padding: 35,
  },
  coverBox: {
    backgroundColor: '#009fe3',
    minHeight: 300,
    marginBottom: 50,
    position: 'absolute',
    maxWidth: 250,
    minWidth: 250,
    top: 150,
    left: 75,
    padding: 25,
    color: 'white',
  },
  coverBoxSubHead: {
    fontSize: 15,
    fontFamily: 'Averta',
  },
  coverBoxTitle: {
    paddingTop: 10,
    fontSize: 25,
  },
  coverBoxBJESeperator: {
    borderBottomColor: 'white',
    borderBottomWidth: 2.5,
    width: 24,
    paddingTop: 5,
  },
  coverBoxContentText: {
    paddingTop: 25,
    flexShrink: 1,
    maxWidth: 250,
    fontSize: 13,
    lineHeight: '15px',
    fontFamily: 'AvertaLight',
  },
  coverPageContactInfo: {
    flexDirection: 'column',
    top: 25,
    padding: 30,
  },
  coverPageSection: {
    padding: 10,
    flexGrow: 1,
    fontFamily: 'AvertaLight',
  },
  coverPageSectionTitle: {
    fontFamily: 'Averta',
    color: '#009fe3',
    fontSize: 16,
    paddingBottom: 3,
  },
  padLeft20: {
    paddingLeft: 20,
  },
  padTop: {
    paddingBottom: 10,
  },
  padBottom: {
    paddingBottom: 10,
  },
  marRight: {
    marginRight: 50,
  },
  datesWrapper: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  flexDirectionColumn: {
    flexDirection: 'rocolumnw',
  },
  widthLabelColumn: {
    width: '20mm',
  },
  regularTitle: {
    fontFamily: 'Averta',
  },
  sectionContent: {
    fontSize: 12,
  },
})

function CoverPage({customer, adm, appointmentDate, consulting, rating}) {
  const nextAppointmentDate =
    consulting.nextAppointment != null
      ? new Date(consulting.nextAppointment).toLocaleDateString('de-DE', {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })
      : null
  return (
    <Page size="A4" style={styles.page}>
      <Image style={styles.coverImage} src={CoverBackground} />
      <View style={styles.coverBox}>
        <Text style={styles.coverBoxSubHead}>Busch-eDialog</Text>
        <Text style={styles.coverBoxTitle}>
          Hallo {customer.firstname} {customer.lastname}
        </Text>
        <View style={styles.coverBoxBJESeperator} />
        <Text style={styles.coverBoxContentText}>
          Hier ist die Zusammenfassung des Treffens mit {adm.firstname}{' '}
          {adm.lastname}, Busch-Jaeger Elektro, {appointmentDate}.
        </Text>
      </View>
      <View style={styles.coverPageContactInfo}>
        <View style={styles.coverPageSection}>
          <View style={styles.datesWrapper}>
            <View style={styles.flexDirectionRow}>
              <Text style={styles.coverPageSectionTitle}>Termin: </Text>
              <Text> {appointmentDate}</Text>
            </View>
            {consulting.nextAppointment != null && (
              <View style={styles.flexDirectionRow}>
                <Text style={styles.coverPageSectionTitle}>
                  Nächster Termin:{' '}
                </Text>
                <Text> {nextAppointmentDate}</Text>
              </View>
            )}
          </View>
        </View>
        <View style={styles.coverPageSection}>
          <Text style={styles.coverPageSectionTitle}>Ihre Kontaktdaten</Text>
          <View style={styles.sectionContent}>
            <View>
              <Text>
                {customer.salutation} {customer.firstname} {customer.lastname}
              </Text>
              <Text>{customer.email}</Text>
            </View>
          </View>
        </View>
        <View style={styles.coverPageSection}>
          <Text style={styles.coverPageSectionTitle}>Ihr Ansprechpartner</Text>
          <View style={styles.sectionContent}>
            <View>
              <Text>
                {adm.salutation} {adm.firstname} {adm.lastname}
              </Text>
              <Text>Tel: {adm.phonenr}</Text>
              <Text>Fax: {adm.faxnr}</Text>
              <Text>{adm.email}</Text>
            </View>
          </View>
        </View>
        <View style={styles.coverPageSection}>
          <View style={styles.sectionContent}>
            {rating !== undefined && rating !== 0 && (
              <Text>Bewertung: {rating} von 5</Text>
            )}
          </View>
        </View>
      </View>
    </Page>
  )
}

function MisisonToZeroPDF({data}) {
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Mission to Zero</Text>
      <View style={styles.sectionContent}>
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={styles.regularTitle}>Notizen: </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function BuschFreeAtHomePDF({data}) {
  let hasSeminar = false
  let seminarDate

  if (
    data.seminarAppointment.value !== null &&
    data.seminarAppointment.value !== '' &&
    data.seminarAppointment.value !== '0001-01-01T00:00:00Z'
  ) {
    hasSeminar = true
    seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
      'de-DE',
      options,
    )
  }
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Busch-free@home®</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <View>
            <LoopInfo data={data} />
          </View>
        </View>
        {hasSeminar && (
          <View style={styles.padBottom}>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Seminar:{' '}
              </Text>
              <View style={styles.flexDirectionColumn}>
                <Text>{seminarDate}</Text>
                <Text>{data.seminarNote.value}</Text>
              </View>
            </View>
          </View>
        )}
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function BuschWelcomePDF({data}) {
  let hasSeminar = false
  let seminarDate

  if (
    data.seminarAppointment.value !== null &&
    data.seminarAppointment.value !== '' &&
    data.seminarAppointment.value !== '0001-01-01T00:00:00Z'
  ) {
    hasSeminar = true
    seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
      'de-DE',
      options,
    )
  }
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Busch-Welcome®</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <LoopInfo data={data} />
        </View>
        {hasSeminar && (
          <View style={styles.padBottom}>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Seminar:{' '}
              </Text>
              <View style={styles.flexDirectionColumn}>
                <Text>{seminarDate}</Text>
                <Text>{data.seminarNote.value}</Text>
              </View>
            </View>
          </View>
        )}
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function BuschWelcomeIPPDF({data}) {
  let hasSeminar = false
  let seminarDate

  if (
    data.seminarAppointment.value !== null &&
    data.seminarAppointment.value !== '' &&
    data.seminarAppointment.value !== '0001-01-01T00:00:00Z'
  ) {
    hasSeminar = true
    seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
      'de-DE',
      options,
    )
  }
  return (
    <View wrap={false} style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Busch-Welcome® IP</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <LoopInfo data={data} />
        </View>
        {hasSeminar && (
          <View style={styles.padBottom}>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Seminar:{' '}
              </Text>
              <View style={styles.flexDirectionColumn}>
                <Text>{seminarDate}</Text>
                <Text>{data.seminarNote.value}</Text>
              </View>
            </View>
          </View>
        )}
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function AbbKnx({data}) {
  let hasSeminar = false
  let seminarDate

  if (
    data.seminarAppointment.value !== null &&
    data.seminarAppointment.value !== '' &&
    data.seminarAppointment.value !== '0001-01-01T00:00:00Z'
  ) {
    hasSeminar = true
    seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
      'de-DE',
      options,
    )
  }
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>ABB i-bus® KNX</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <View>
            <LoopInfo data={data} />
          </View>
        </View>
        {hasSeminar && (
          <View style={styles.padBottom}>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Seminar:{' '}
              </Text>
              <View style={styles.flexDirectionColumn}>
                <Text>{seminarDate}</Text>
                <Text>{data.seminarNote.value}</Text>
              </View>
            </View>
          </View>
        )}
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function ComfortLine({data}) {
  let hasSeminar = false
  let seminarDate

  if (
    data.seminarAppointment.value !== null &&
    data.seminarAppointment.value !== '' &&
    data.seminarAppointment.value !== '0001-01-01T00:00:00Z'
  ) {
    hasSeminar = true
    seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
      'de-DE',
      options,
    )
  }
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>ComfortLine</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <View>
            <LoopInfo data={data} />
          </View>
        </View>
        {hasSeminar && (
          <View style={styles.padBottom}>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Seminar:{' '}
              </Text>
              <View style={styles.flexDirectionColumn}>
                <Text>{seminarDate}</Text>
                <Text>{data.seminarNote.value}</Text>
              </View>
            </View>
          </View>
        )}
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function InstallationsGeraete({data}) {
  let hasSeminar = false
  let seminarDate

  if (
    data.seminarAppointment.value !== null &&
    data.seminarAppointment.value !== '' &&
    data.seminarAppointment.value !== '0001-01-01T00:00:00Z'
  ) {
    hasSeminar = true
    seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
      'de-DE',
      options,
    )
  }
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Installationsgeräte</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <View>
            <LoopInfo data={data} />
          </View>
        </View>
        {hasSeminar && (
          <View style={styles.padBottom}>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Seminar:{' '}
              </Text>
              <View style={styles.flexDirectionColumn}>
                <Text>{seminarDate}</Text>
                <Text>{data.seminarNote.value}</Text>
              </View>
            </View>
          </View>
        )}
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function BeruehrungslosesSchalten({data}) {
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Berührungsloses Schalten</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <View>
            <LoopInfo data={data} />
          </View>
        </View>
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function Cwa({data}) {
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Lichtschalter</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <View>
            <LoopInfo data={data} />
          </View>
        </View>
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function ServiceAndToolsPDF({data}) {
  let hasSeminar = false
  let seminarDate

  if (
    data.seminarAppointment.value !== null &&
    data.seminarAppointment.value !== '' &&
    data.seminarAppointment.value !== '0001-01-01T00:00:00Z'
  ) {
    hasSeminar = true
    seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
      'de-DE',
      options,
    )
  }
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Service & Tools</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <LoopInfo data={data} />
        </View>
        {hasSeminar && (
          <View style={styles.padBottom}>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Seminar:{' '}
              </Text>
              <View style={styles.flexDirectionColumn}>
                <Text>{seminarDate}</Text>
                <Text>{data.seminarNote.value}</Text>
              </View>
            </View>
          </View>
        )}
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function BuschflexPDF({data}) {
  let hasSeminar = false
  let seminarDate

  if (
    data.seminarAppointment.value !== null &&
    data.seminarAppointment.value !== '' &&
    data.seminarAppointment.value !== '0001-01-01T00:00:00Z'
  ) {
    hasSeminar = true
    seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
      'de-DE',
      options,
    )
  }
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Busch-free@home® flex</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <LoopInfo data={data} />
        </View>
        {hasSeminar && (
          <View style={styles.padBottom}>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Seminar:{' '}
              </Text>
              <View style={styles.flexDirectionColumn}>
                <Text>{seminarDate}</Text>
                <Text>{data.seminarNote.value}</Text>
              </View>
            </View>
          </View>
        )}
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function UK600AndAK600PDF({data}) {
  let hasSeminar = false
  let seminarDate

  if (
    data.seminarAppointment.value !== null &&
    data.seminarAppointment.value !== '' &&
    data.seminarAppointment.value !== '0001-01-01T00:00:00Z'
  ) {
    hasSeminar = true
    seminarDate = new Date(data.seminarAppointment.value).toLocaleDateString(
      'de-DE',
      options,
    )
  }
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>UK600 + AK600</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <LoopInfo data={data} />
        </View>
        {hasSeminar && (
          <View style={styles.padBottom}>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Seminar:{' '}
              </Text>
              <View style={styles.flexDirectionColumn}>
                <Text>{seminarDate}</Text>
                <Text>{data.seminarNote.value}</Text>
              </View>
            </View>
          </View>
        )}
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function AbbSmarterMobilityPDF({data}) {
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>ABB Smarter Mobility</Text>
      <View style={styles.sectionContent}>
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function LbInnovationsPDF({data}) {
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Neuheiten 2022</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <LoopInfo data={data} />
        </View>
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function EnergiemonitoringPDF({data}) {
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Energiemonitoring</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <LoopInfo data={data} />
        </View>
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function ModerneElektroinstallationPDF({data}) {
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Moderne Elektroinstallation</Text>
      <View style={styles.sectionContent}>
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function USVPDF({data}) {
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Unterbrechungsfreie Stromversorgung (USV)</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <LoopInfo data={data} />
        </View>
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function NeuheitenPDF({data}) {
  return (
    <View style={styles.section}>
      <Text style={styles.coverPageSectionTitle}>Neuheiten 2024</Text>
      <View style={styles.sectionContent}>
        <View style={styles.padBottom}>
          <Text style={styles.regularTitle}>Ihre Verkaufsunterstützung</Text>
          <LoopInfo data={data} />
        </View>
        {data.notes.value !== '' && (
          <View>
            <View style={styles.flexDirectionRow}>
              <Text style={[styles.regularTitle, styles.widthLabelColumn]}>
                Notizen:{' '}
              </Text>
              <Text style={styles.marRight}>{data.notes.value}</Text>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

function ExportPDF({result, rating}) {
  Font.register({
    family: 'Averta',
    src: Averta,
    fontStyle: 'normal',
    fontWeight: 'normal',
  })
  Font.register({
    family: 'AvertaLight',
    src: AvertaLight,
    fontStyle: 'normal',
    fontWeight: 'normal',
  })
  const {
    setup: customer,
    settings: adm,
    consulting,
    freeathome,
    missiontozero,
    welcome,
    welcomeip,
    knx,
    comfortline,
    installationsgeraete,
    beruehrungslosesschalten,
    cwa,
    serviceandtools,
    buschflex,
    uk600ak600,
    abbSmarterMobility,
    lbInnovations,
    energiemonitoring,
    moderneElektroinstallation,
    usv,
    neuheiten2024
  } = result
  let activeChapters = Object.keys(consulting.activeChapters).filter(
    chapter => result.consulting.activeChapters[chapter],
  )
  const appointmentDate = new Date(customer.selectedDate).toLocaleDateString(
    'de-DE',
    options,
  )
  return (
    <Document>
      <CoverPage
        customer={customer}
        adm={adm}
        consulting={consulting}
        appointmentDate={appointmentDate}
        rating={rating}
      />
      <Page size="A4" style={styles.page}>
        <Image src={BJELogo} style={styles.bjeLogoTopRight} />
        {activeChapters.includes('missiontozero') && (
          <MisisonToZeroPDF data={missiontozero} />
        )}
        {activeChapters.includes('freeathome') && (
          <BuschFreeAtHomePDF data={freeathome} />
        )}
        {activeChapters.includes('welcome') && (
          <BuschWelcomePDF data={welcome} />
        )}
        {activeChapters.includes('welcomeip') && (
          <BuschWelcomeIPPDF data={welcomeip} />
        )}
        {activeChapters.includes('knx') && <AbbKnx data={knx} />}
        {activeChapters.includes('comfortline') && (
          <ComfortLine data={comfortline} />
        )}
        {activeChapters.includes('installationsgeraete') && (
          <InstallationsGeraete data={installationsgeraete} />
        )}
        {activeChapters.includes('beruehrungslosesschalten') && (
          <BeruehrungslosesSchalten data={beruehrungslosesschalten} />
        )}
        {activeChapters.includes('cwa') && <Cwa data={cwa} />}
        {activeChapters.includes('serviceandtools') && (
          <ServiceAndToolsPDF data={serviceandtools} />
        )}
        {activeChapters.includes('buschflex') && (
          <BuschflexPDF data={buschflex} />
        )}
        {activeChapters.includes('uk600ak600') && <UK600AndAK600PDF data={uk600ak600} />}
        {activeChapters.includes('abbSmarterMobility') && (
          <AbbSmarterMobilityPDF data={abbSmarterMobility} />
        )}
        {activeChapters.includes('lbInnovations') && (
          <LbInnovationsPDF data={lbInnovations} />
        )}
        {activeChapters.includes('energiemonitoring') && (
          <EnergiemonitoringPDF data={energiemonitoring} />
        )}
        {activeChapters.includes('moderneElektroinstallation') && (
          <ModerneElektroinstallationPDF data={moderneElektroinstallation} />
        )}
        {activeChapters.includes('usv') && (
          <USVPDF data={usv} />
        )}
        {activeChapters.includes('neuheiten2024') && (
          <NeuheitenPDF data={neuheiten2024} />
        )}
      </Page>
    </Document>
  )
}

function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true
  } else {
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    )
  }
}

function LoopInfo({data}) {
  const renderData = []
  Object.entries(data).forEach(([key, entry]) => {
    //eslint-disable-line array-callback-return
    if (typeof entry.value === 'number') {
      renderData.push(
        entry.value > 0 && (
          <Text>
            {entry.value}x {entry.description}
          </Text>
        ),
      )
    } else if (typeof entry.value === 'boolean') {
      renderData.push(entry.value && <Text>{entry.description}</Text>)
    }
  })

  return renderData
}

export default function DownloadPdf({result, rating}) {
  const isIpad = isIOS()
  return (
    <div>
      {process.env.NODE_ENV === 'development' ? (
        <PDFViewer>
          <ExportPDF result={result} rating={rating} />
        </PDFViewer>
      ) : (
        <PDFDownloadLink
          document={<ExportPDF result={result} rating={rating} />}
          fileName="export.pdf"
        >
          {({blob, url, loading, error}) =>
            loading ? (
              ''
            ) : (
              <a
                href={url}
                rel="noopener noreferrer"
                target={isIpad ? '_self' : '_blank'}
              >
                <button className="q-btn mt-3 q-btn-primary q-small">
                  Als PDF exportieren
                </button>
              </a>
            )
          }
        </PDFDownloadLink>
      )}
    </div>
  )
}
