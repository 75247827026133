import React from 'react'
import BackgroundImage from '../assets/img/0293_10_Jalousiesteuerung_170608_extra_teppich.jpg'

export default function Header(props) {
    const  { appointmentDate, customerFirstname, customerLastname, admFirstname, admLastname, customerSalutation, customerEmail } = props
    
    let namePhrase = <>Hallo <br/>{`${customerFirstname} ${customerLastname}`}</>

    if((customerFirstname === "") && (customerLastname !== "")) {
        namePhrase = <>Hallo <br/>{`${customerSalutation} ${customerLastname}`}</>
    } else if((customerLastname === "") && (customerFirstname !== "")) {
        namePhrase = <>Hallo <br/>{`${customerFirstname}`}</>
    } else if((customerFirstname === "") && (customerLastname === "")) {
        namePhrase = <>Hallo <br/>{`${customerEmail}`}</>
    }

    return (
        <div className="q-hero-section">
            <div className="container">
                <div className="q-hero-image">
                    <img src={BackgroundImage} alt=""/>
                </div>
                <div className="row q-hero-content">
                    <div className="col-12 col-lg-5">
                        <div>
                            <div className="q-blue-box">
                                <div>
                                    <h3 className="white mb-3">Busch-eDialog</h3>
                                    <h2 className="q-blue-box-header stroke-short-solid">
                                        {namePhrase}
                                    </h2>
                                    <p>
                                        Hier ist die Zusammenfassung des Treffens mit
                                        <strong> {admFirstname} {admLastname}</strong>,
                                        Busch-Jaeger Elektro, am <strong>{appointmentDate}</strong>.
                                    </p>
                                </div>
                                <div className="q-blue-box-body">
                                    <a href="#anchors" className="scrolly mt-4 q-btn q-btn-secondary invers q-small">Ansehen</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
