import React from 'react'

export default function LoopInfo({data}) {
    const renderData = []
    
    {data && Object.entries(data).map(([key, entry]) => { //eslint-disable-line array-callback-return
        if(typeof(entry.value) === "number") {
            renderData.push(
                entry.value > 0 && <li>{entry.value}x {entry.description}</li>
            )
        } else if (typeof(entry.value) === "boolean") {
            renderData.push(
                entry.value && <li>{entry.description}</li>
            )
        }
    })}

    return renderData
}
