import React, { Component } from "react";
import BJELogo from "../assets/img/Logo.svg";
import StarRating from "./Rating.js";
import CircularIndeterminate from "./CircularLoader.js";
import Header from "./Header";
import Anchorlinks from "./Anchorlinks.js";
import Footer from "./Footer";
import NavBar from "./NavBar";
import NextAppointment from "./NextAppointment";
import DownloadPdf from "./ExportPDF/ExportPDF";
import ProductRating from "./ProductRating";
import { chapterData } from "../data.js";
import LoopInfo from "./LoopInfo.js";

// Dayjs
import dayjs from "dayjs";
require("dayjs/locale/de");

export default class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      fetchResult: {},
      result: {},
      rating: 0,
      productRating: {},
    };
    this.redirectToScope = this.redirectToScope.bind(this);
  }

  componentDidMount() {
    let hash = this.props.id; //eslint-disable-line no-undef
    let that = this;
    let id = null;

    const regex =
      /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gm;
    let m;

    while ((m = regex.exec(hash)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      //eslint-disable-next-line no-loop-func
      m.forEach((match, groupIndex) => {
        id = match;
      });
    }
    if (id == null) {
      console.error("PROVIDE ID!");
    } else {
      let data = null;
      let xhr = new XMLHttpRequest();
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            const res = JSON.parse(this.responseText);
            that.setState({
              isFetching: false,
              result: res.data,
              rating: res.rating,
              productRating: res.productRating,
              fetchResult: res,
            });
          } else {
            console.error("REQUEST FAILED!");
          }
        }
      });
      xhr.open("GET", `${process.env.REACT_APP_API_URL}consultation/${id}`);
      xhr.send(data);
    }
  }

  redirectToScope(url) {
    document.location = url; //eslint-disable-line no-undef
  }

  render() {
    const { isFetching, result, rating, productRating } = this.state;
    const indicator = <CircularIndeterminate />;
    if (isFetching) {
      return (
        <div
          style={{ alignSelf: "center", margin: "0 auto" }}
          className="loadingScreen"
        >
          <div className="loader d-flex justify-content-center align-items-center">
            {indicator}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-5 loader-text">
            <img src={BJELogo} alt="logo" /> |{" "}
            <p className="mb-0 ml-3">Busch-eDialog</p>
          </div>
        </div>
      );
    }

    const { id } = this.props;
    const options = { weekday: "long", month: "long", day: "numeric" };
    const { setup: customer, settings: adm, consulting } = result;
    let activeChapters = Object.keys(consulting.activeChapters).filter(
      (chapter) => result.consulting.activeChapters[chapter]
    );
    const appointmentDate = new Date(customer.selectedDate).toLocaleDateString(
      "de-DE",
      options
    );

    function filterAssets(obj) {
      // Überprüfen, ob das übergebene Objekt vom Typ "Object" ist
      if (typeof obj !== "object" || obj === null) {
        return;
      }

      // Ergebnisobjekt erstellen
      const result = {};

      // Durch das ursprüngliche Objekt iterieren
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          // Überprüfen, ob der Wert des aktuellen Schlüssels vom Typ "number" oder "boolean" ist
          if (obj[key].value == 1 || obj[key].value == true) {
            // Das aktuelle Paar von Schlüssel und Wert zum Ergebnisobjekt hinzufügen
            result[key] = obj[key];
          }
        }
      }

      return result;
    }

    return (
      <div key="dashboard">
        <NavBar />
        <main id="q-page-content">
          <Header
            appointmentDate={appointmentDate}
            customerFirstname={customer.firstname}
            customerLastname={customer.lastname}
            customerSalutation={customer.salutation}
            customerEmail={customer.email}
            admSalutation={adm.salutation}
            admFirstname={adm.firstname}
            admLastname={adm.lastname}
          />
          <Anchorlinks
            activeChapters={activeChapters}
            notes={consulting.notes !== ""}
            nextAppointment={consulting.nextAppointment}
          />
          <div id="wrapper">
            {activeChapters.map((chapter, i) => {
              const currentChapter = chapterData[chapter];
              const currentNotes =
                result[currentChapter.slug]?.notes?.value || null;
              const seminarData =
                result[currentChapter.slug]?.seminarAppointment;

              // Seminar
              const options = {
                weekday: "long",
                month: "long",
                day: "numeric",
              };

              let hasSeminar = false;
              let seminarDateLong, seminarDateShort;

              if (
                seminarData?.value !== undefined &&
                seminarData.value !== null &&
                seminarData.value !== "" &&
                seminarData.value !== "0001-01-01T00:00:00Z"
              ) {
                hasSeminar = true;
                seminarDateLong = dayjs(seminarData.value)
                  .locale("de")
                  .format("dddd, DD. MMMM YYYY");
                seminarDateShort = dayjs(seminarData.value)
                  .locale("de")
                  .format("DD.MMMM");
              }

              const filteredAssets = filterAssets(result[currentChapter.slug]);
              return (
                activeChapters.includes(chapter) && (
                  <section id={chapter} style={{ paddingTop: "100px" }}>
                    <header>
                      <h2>{currentChapter.label}</h2>
                      <span className="image main">
                        <img src={currentChapter.img} alt="" />
                      </span>
                    </header>
                    <div className="content mt-md-5">
                      {/* SEMINAR */}
                      {seminarData?.value && (
                        <div className="q-collapse-wrapper">
                          <div className="q-collapse w-100 d-flex flex-column">
                            <a
                              className="q-collapse-link d-flex w-100 collapsed"
                              data-toggle="collapse"
                              href={`#${currentChapter.slug}Seminar`}
                              role="button"
                              aria-expanded="false"
                              aria-controls={`${currentChapter.slug}Seminar`}
                            >
                              <span className="q-close-button-wrapper ">
                                <span className="q-close-button"></span>
                              </span>
                              Ihr Seminar am {seminarDateShort}
                            </a>
                            <div
                              className="collapse pt-3"
                              id={`${currentChapter.slug}Seminar`}
                            >
                              <p>{seminarDateLong}</p>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* WEITERFÜHRENDE LINKS */}
                      {currentChapter.links && (
                        <div className="q-collapse-wrapper">
                          <div className="q-collapse w-100 d-flex flex-column">
                            <a
                              className="q-collapse-link d-flex w-100 collapsed"
                              data-toggle="collapse"
                              href={`#${currentChapter.slug}Links`}
                              role="button"
                              aria-expanded="false"
                              aria-controls={`${currentChapter.slug}Links`}
                            >
                              <span className="q-close-button-wrapper ">
                                <span className="q-close-button"></span>
                              </span>
                              Weiterführende Links
                            </a>
                            <div
                              className="collapse pt-3"
                              id={`${currentChapter.slug}Links`}
                            >
                              <ul class="list-group list-group-flush">
                                {currentChapter.links.map((link, i) => {
                                  return (
                                    <li class="list-group-item">
                                      <a
                                        href={link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        » {link.bezeichnung}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* VERKAUFSUNTERSTÜTZUNG (Bestellungen) */}
                      {typeof filteredAssets === "object" &&
                        Object.keys(filteredAssets).length != 0 && (
                          <div className="q-collapse-wrapper">
                            <div className="q-collapse w-100 d-flex flex-column">
                              <a
                                className="q-collapse-link d-flex w-100 collapsed"
                                data-toggle="collapse"
                                href={`#${currentChapter.slug}Orders`}
                                role="button"
                                aria-expanded="false"
                                aria-controls={`${currentChapter.slug}Orders`}
                              >
                                <span className="q-close-button-wrapper ">
                                  <span className="q-close-button"></span>
                                </span>
                                Ihre Verkaufsunterstützung
                              </a>
                              <div
                                className="collapse pt-3"
                                id={`${currentChapter.slug}Orders`}
                              >
                                <ul>
                                  <LoopInfo
                                    data={result[currentChapter.slug]}
                                  />
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      {/* NOTIZEN */}
                      {currentNotes && (
                        <div className="q-collapse-wrapper">
                          <div className="q-collapse w-100 d-flex flex-column">
                            <a
                              className="q-collapse-link d-flex w-100 collapsed"
                              data-toggle="collapse"
                              href={`#${currentChapter.slug}Notes`}
                              role="button"
                              aria-expanded="false"
                              aria-controls={`${currentChapter.slug}Notes`}
                            >
                              <span className="q-close-button-wrapper ">
                                <span className="q-close-button"></span>
                              </span>
                              Notizen
                            </a>
                            <div
                              className="collapse pt-3"
                              id={`${currentChapter.slug}Notes`}
                            >
                              <p style={{ whiteSpace: "pre-wrap" }}>
                                {currentNotes}
                              </p>
                              <button className="q-btn q-btn-primary q-small">
                                Noch Fragen?
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* VERLINKUNG VON PRÄSENTATION */}
                      <a
                        href={`https://playbook.busch-jaeger.de${currentChapter.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Präsentation aus Gespräch erneut ansehen
                      </a>
                    </div>
                  </section>
                )
              );
            })}

            <section id="contact">
              <header>
                <h2>Ihr Ansprechpartner</h2>
              </header>
              <div className="content">
                <p>
                  <b>
                    {result.settings.salutation} {result.settings.firstname}{" "}
                    {result.settings.lastname}
                  </b>
                  <br />
                  <a
                    href={`mailto:${result.settings.email}`}
                    title="Öffnet ein Fenster zum Versenden der E-Mail"
                  >
                    {result.settings.email}
                  </a>
                  {result.settings?.phonenr && (
                    <>
                      <br />
                      Tel: {result.settings.phonenr}
                    </>
                  )}
                  {result.settings?.faxnr && (
                    <>
                      <br />
                      <li>Fax: {result.settings.faxnr}</li>
                    </>
                  )}
                </p>
                <a
                  className="q-btn mt-2 q-btn-primary q-small"
                  href={`mailto:${result.settings.email}?subject=Busch%20eDialog:%20Kontaktaufnahme`}
                >
                  Kontakt aufnehmen
                </a>
              </div>
            </section>

            <section id="rating">
              <header>
                <h2>
                  Wie zufrieden sind Sie mit der neuen Form des
                  Beratungsgesprächs.
                </h2>
              </header>
              <div className="content">
                <div className="row">
                  <div className="col-12">
                    {rating === 0 ? (
                      <p className="mb-1">
                        <strong>Ihre Meinung zum Beratungsgespräch</strong>
                      </p>
                    ) : (
                      <p className="mb-1">
                        <strong>Vielen Dank für Ihre Bewertung</strong>
                      </p>
                    )}
                    <StarRating id={id} rating={rating} />
                  </div>
                </div>
              </div>
            </section>
            <section id="rating">
              <header>
                <h2>Produkte bewerten</h2>
              </header>
              <div className="content">
                {activeChapters.map((chapter, i) => {
                  const currentChapter = chapterData[chapter];
                  console.log(currentChapter.slug.includes("jahresgespraech"));
                  return !currentChapter.slug.includes("jahresgespraech") ? (
                    <div className="row">
                      <div className="col-12">
                        <p className="mb-1">
                          <strong>{currentChapter.label}</strong>
                        </p>
                        <ProductRating
                          id={id}
                          productRating={productRating}
                          prodId={currentChapter.slug}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
            </section>

            <section id="profile">
              <header>
                <h2>Ihre Kontaktdaten</h2>
              </header>
              <div className="content">
                <p>
                  <b>
                    {result.setup.salutation} {result.setup.firstname}{" "}
                    {result.setup.lastname}
                  </b>
                </p>
                <a
                  className="q-btn mt-2 q-btn-primary q-small"
                  href={`mailto:${result.settings.email}?subject=Busch%20eDialog:%20Aktualisierung%20Kontaktdaten`}
                >
                  Aktualisierung mitteilen
                </a>
              </div>
            </section>
            {dayjs(consulting.nextAppointment).year() !== 1 ||
            dayjs(consulting.nextAppointment).isValid() ? (
              <NextAppointment consulting={consulting} adm={adm} />
            ) : (
              <></>
            )}
            <section id="pdf">
              <div className="content">
                <DownloadPdf result={result} rating={rating} />
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}
