import React from "react";

// Chapter Images
import MissionToZeroImage from "./assets/_chapter-images/mission-to-zero.webp";
import BuschWelcomeImage from "./assets/_chapter-images/welcome.webp";
import BuschFreeAtHomeImage from "./assets/_chapter-images/free-at-home.webp";
import BuschWelcomeIPImage from "./assets/_chapter-images/welcome-ip.webp";
import ABBiBusKNXImage from "./assets/_chapter-images/abb-knx.webp";
import ComfortLineImage from "./assets/_chapter-images/comfortline.webp";
import InstallationsgeraeteImage from "./assets/_chapter-images/installationsgeraete.webp";
import BeruehrungslosesSchaltenImage from "./assets/_chapter-images/beruehrungsloses-schalten.webp";
import SchaltenUndSteuernImage from "./assets/_chapter-images/schalten-und-steuern.webp";
import ArchitektenImage from "./assets/_chapter-images/architekten.webp";
import ServiceAndToolsImage from "./assets/_chapter-images/service-and-tools.webp";
import BuschFlexImage from "./assets/_chapter-images/busch-flex.webp";
import CylonImage from "./assets/_chapter-images/abb-cylon.webp";
import UK600AK600Image from "./assets/_chapter-images/uk-600.webp";
import SmarterGalleryImage from "./assets/_chapter-images/smarter-gallery.webp";
import ABBSmarterMobilityImage from "./assets/_chapter-images/abb-smarter-mobility.webp";
import LBInnovationsImage from "./assets/_chapter-images/lbInnovations.webp";
import EnergiemonitoringImage from "./assets/_chapter-images/energiemonitoring.webp";
import ModerneElektroinstallationImage from "./assets/_chapter-images/moderne-elektroinstallation.webp";
import USVImage from "./assets/_chapter-images/usv.webp";
import NewsUndAktionenImage from "./assets/_chapter-images/news.webp";
import JahresgespraecheImage from "./assets/_chapter-images/jahresgespraech-2024.webp";
import FlexLineImage from "./assets/_chapter-images/flexline.webp";
import Neuheiten2024Image from "./assets/_chapter-images/neuheiten-2024.webp";

export const chapterData = {
  missiontozero: {
    slug: "missiontozero",
    label: "Mission to Zero",
    link: "/mission-to-zero",
    chapterID: 0,
    company: ["bje"],
    img: MissionToZeroImage,
  },
  welcome: {
    slug: "welcome",
    label: (
      <>
        Busch-Welcome<sup>®</sup>
      </>
    ),
    shortLabel: "Busch-Welcome",
    link: "/welcome",
    chapterID: 2,
    company: ["bje"],
    img: BuschWelcomeImage,
    links: [
      {
        url: "https://busch-jaeger-konfigurator.de/de",
        bezeichnung: "Busch-Welcome® Konfigurator",
      },
    ],
  },
  freeathome: {
    slug: "freeathome",
    label: (
      <>
        Busch-free@home<sup>®</sup>
      </>
    ),
    shortLabel: "Busch-free@home",
    link: "/free-at-home",
    chapterID: 1,
    company: ["bje"],
    img: BuschFreeAtHomeImage,
    links: [
      {
        url: "https://smart-home-configurator.my.busch-jaeger.de",
        bezeichnung: "Smart Home Konfigurator",
      },
      {
        url: "https://www.busch-jaeger.de/busch-freehome",
        bezeichnung: "Busch-Jaeger Website",
      },
      ,
      {
        url: "https://youtu.be/aYfmKMELUmM",
        bezeichnung: "Smarter Home Story",
      },
    ],
  },
  welcomeip: {
    slug: "welcomeip",
    label: (
      <>
        Busch-Welcome<sup>®</sup> IP
      </>
    ),
    shortLabel: "Busch-Welcome IP",
    link: "/welcome-ip",
    chapterID: 3,
    company: ["bje"],
    img: BuschWelcomeIPImage,
    links: [
      {
        url: "https://smart-home-configurator.my.busch-jaeger.de",
        bezeichnung: "Smart Home Konfigurator",
      },
      {
        url: "https://www.busch-jaeger.de/smarter-home/systeme/busch-welcome-ip",
        bezeichnung: "Busch-Jaeger Website",
      },
    ],
  },
  knx: {
    slug: "knx",
    label: (
      <>
        ABB i-bus<sup>®</sup> KNX
      </>
    ),
    shortLabel: "ABB i-bus KNX",
    link: "/knx",
    chapterID: 4,
    company: ["abb"],
    img: ABBiBusKNXImage,
  },
  comfortline: {
    slug: "comfortline",
    label: "ComfortLine",
    link: "/comfort-line",
    chapterID: 5,
    company: ["suj"],
    img: ComfortLineImage,
  },
  installationsgeraete: {
    slug: "installationsgeraete",
    label: "Installationsgeräte Highlights",
    link: "/installationsgeraete",
    chapterID: 6,
    company: ["bje"],
    img: InstallationsgeraeteImage,
  },
  beruehrungslosesschalten: {
    slug: "beruehrungslosesschalten",
    label: "Berührungsloses Schalten",
    link: "/beruehrungsloses-schalten",
    chapterID: 7,
    company: ["bje"],
    img: BeruehrungslosesSchaltenImage,
    links: [
      {
        url: "https://www.busch-jaeger.de/produktloesung/bewegungsmelder",
        bezeichnung: "Bewegungsmelder",
      },
      {
        url: "https://www.busch-jaeger.de/produktloesung/praesenzmelder",
        bezeichnung: "Busch-Präsenzmelder",
      },
      {
        url: "https://www.busch-jaeger.de/produkt/busch-komfortschalter",
        bezeichnung: "Busch-Komfortschalter",
      },
    ],
  },
  cwa: {
    slug: "cwa",
    label: "Schalten und Steuern",
    link: "/cwa",
    chapterID: 8,
    company: ["bje"],
    img: SchaltenUndSteuernImage,
  },
  jahresgespraech_2021: {
    slug: "jahresgespraech_2021",
    label: "Jahresgespräch 2021",
    link: "/jahresgespraech-2021",
    chapterID: 9,
    company: ["bje"],
    img: JahresgespraecheImage,
  },
  architekten: {
    slug: "architekten",
    label: "Architekten",
    link: "/architekten",
    chapterID: 11,
    company: ["bje"],
    img: ArchitektenImage,
  },
  jahresgespraech_2022: {
    slug: "jahresgespraech_2022",
    label: "Jahresgespräch 2022",
    link: "/jahresgespraech-2022",
    chapterID: 12,
    company: ["bje"],
    img: JahresgespraecheImage,
  },

  serviceandtools: {
    slug: "serviceandtools",
    label: "Service & Tools",
    link: "/serviceandtools",
    chapterID: 13,
    company: ["bje"],
    img: ServiceAndToolsImage,
  },
  buschflex: {
    slug: "buschflex",
    label: (
      <>
        Busch-flexTronics<sup>®</sup>
      </>
    ),
    shortLabel: "Busch-flexTronics®",
    link: "/busch-flex",
    chapterID: 14,
    company: ["bje"],
    img: BuschFlexImage,
  },
  cylon: {
    slug: "cylon",
    label: (
      <>
        ABB Cylon<sup>®</sup> Intelligente Energiesteuerung
      </>
    ),
    shortLabel: "ABB Cylon",
    link: "/cylon",
    chapterID: 15,
    company: ["abb"],
    img: CylonImage,
  },
  uk600ak600: {
    slug: "uk600ak600",
    label: "UK600 + AK600",
    link: "/uk600-ak600",
    chapterID: 16,
    company: ["suj"],
    img: UK600AK600Image,
  },
  smarterGallery: {
    slug: "smarterGallery",
    label: "Smarter Gallery",
    link: "/smarter-gallery",
    chapterID: 17,
    company: ["bje"],
    img: SmarterGalleryImage,
  },
  abbSmarterMobility: {
    slug: "abbSmarterMobility",
    label: "ABB Smarter Mobility",
    link: "/abb-smarter-mobility",
    chapterID: 18,
    company: ["abb"],
    img: ABBSmarterMobilityImage,
  },
  lbInnovations: {
    slug: "lbInnovations",
    label: "Neuheiten 2022",
    link: "/innovations-2022",
    chapterID: 19,
    company: ["bje", "abb"],
    img: LBInnovationsImage,
  },
  energiemonitoring: {
    slug: "energiemonitoring",
    label: "Energiemonitoring",
    link: "/energiemonitoring",
    chapterID: 20,
    company: ["abb"],
    img: EnergiemonitoringImage,
  },
  jahresgespraech_2023: {
    slug: "jahresgespraech_2023",
    label: "Jahresgespräch 2023",
    link: "/jahresgespraech-2023",
    chapterID: 21,
    company: ["bje"],
    img: JahresgespraecheImage,
  },
  moderneElektroinstallation: {
    slug: "moderneElektroinstallation",
    label: "Moderne Elektroinstallation",
    link: "/moderne-elektroinstallation",
    chapterID: 22,
    company: ["bje"],
    img: ModerneElektroinstallationImage,
  },
  usv: {
    slug: "usv",
    label: "Unterbrechungsfreie Stromversorgung (USV)",
    link: "/usv",
    chapterID: 23,
    company: ["abb"],
    img: USVImage,
  },
  newsUndAktionen: {
    slug: "newsUndAktionen",
    label: "News & Aktionen",
    link: "/news-und-aktionen",
    chapterID: 24,
    company: ["bje", "abb", "suj"],
    img: NewsUndAktionenImage,
  },
  jahresgespraech_2024: {
    slug: "jahresgespraech_2024",
    label: "Jahresgespräch 2024",
    link: "/jahresgespraech-2024",
    chapterID: 25,
    company: ["bje"],
    img: JahresgespraecheImage,
  },
  flexline: {
    slug: "flexline",
    label: (
      <>
        FlexLine<sup>®</sup>
      </>
    ),
    shortLabel: "FlexLine®",
    link: "/flexline",
    chapterID: 26,
    company: ["abb"],
    img: FlexLineImage,
  },
  neuheiten2024: {
    slug: "neuheiten2024",
    label: "Neuheiten 2024",
    shortLabel: "Neuheiten 2024",
    link: "/neuheiten-2024",
    chapterID: 27,
    company: ["abb", "bje", "suj"],
    img: Neuheiten2024Image,
  },
};
